<template>
  <div>
    <el-dialog
      :visible.sync="editShow"
      width="55%"
      :close-on-click-modal="false"
      append-to-body
      :before-close="handleCancel"
    >
      <div slot="title" class="dialog-header">
        <div class="title-content">
          <span class="main-title">工时明细</span>
          <div class="sub-title">
            <template v-if="detail.queryParams">
              <span
                v-if="detail.queryParams.scoreType === 'project'"
                class="tag project-tag"
              >
                <i class="el-icon-s-cooperation"></i> 项目：{{
                  detail.projectName || "未知项目"
                }}
              </span>
              <span
                v-else-if="detail.queryParams.scoreType === 'business'"
                class="tag business-tag"
              >
                <i class="el-icon-s-shop"></i> 商务：{{
                  detail.businessName || "未知商务"
                }}
              </span>
              <span
                v-else-if="detail.queryParams.scoreType === 'manage'"
                class="tag manage-tag"
              >
                <i class="el-icon-s-custom"></i> 部门：{{
                  detail.departmentName || "未知部门"
                }}
              </span>
              <span class="tag user-tag"
                ><i class="el-icon-user"></i>
                {{ detail.userName || "未知员工" }}</span
              >
              <span class="tag date-tag"
                ><i class="el-icon-date"></i>
                {{ detail.examineMonth || "未知月份" }}</span
              >
            </template>
          </div>
        </div>
      </div>

      <!-- <div class="hour-base-info">
        <div
          class="info-item"
          v-if="
            detail.queryParams &&
            detail.queryParams.scoreType === 'project'
          "
        >
          <span class="label">项目名称：</span>
          <span class="value">{{ detail.projectName }}</span>
        </div>
        <div
          class="info-item"
          v-if="
            detail.queryParams &&
            detail.queryParams.scoreType === 'business'
          "
        >
          <span class="label">商务名称：</span>
          <span class="value">{{ detail.businessName }}</span>
        </div>
        <div
          class="info-item"
          v-if="
            detail.queryParams &&
            detail.queryParams.scoreType === 'manage'
          "
        >
          <span class="label">部门名称：</span>
          <span class="value">{{ detail.workName }}</span>
        </div>
        <div class="info-item">
          <span class="label">员工姓名：</span>
          <span class="value">{{ detail.userName }}</span>
        </div>
        <div class="info-item">
          <span class="label">考核月份：</span>
          <span class="value">{{ detail.examineMonth }}</span>
        </div>
      </div> -->
      <!-- <el-table
        :data="tableData"
        style="width: 100%"
        border
        v-loading="loading"
      >
        <el-table-column
          align="center"
          width="120px"
          prop="manhourDate"
          label="工时日期"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.manhourDate.slice(0, 10) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          width="100px"
          prop="auditHour"
          label="审核工时"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="workContent"
          label="工作内容"
          :show-overflow-tooltip="false"
        >
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.workContent"
              disabled
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4 }"
            >
            </el-input>
          </template>
        </el-table-column>
      </el-table> -->
      <div class="calendar-content-layout">
        <!-- 左侧日历部分 -->
        <div class="calendar-container">
          <el-calendar v-model="currentDate">
            <template slot="dateCell" slot-scope="{ date, data }">
              <div
                class="calendar-day"
                :class="{
                  'has-data': hasDataOnDate(date),
                  'is-selected': isSelectedDate(date),
                }"
                @click="selectDate(date)"
              >
                <span>{{ data.day.split("-").slice(2).join("") }}</span>
                <div v-if="hasDataOnDate(date)" class="dot"></div>
              </div>
            </template>
          </el-calendar>
        </div>

        <!-- 右侧内容部分 -->
        <div class="content-container">
          <div v-if="selectedDateData.length > 0" class="date-info">
            <div class="date-header">
              <span class="date-title">{{ formatSelectedDate }}</span>
              <span class="total-hours">总工时: {{ getTotalHours }} 小时</span>
            </div>

            <el-divider></el-divider>

            <div
              v-for="(item, index) in selectedDateData"
              :key="index"
              class="work-item"
            >
              <div class="work-header">
                <span class="work-hours">{{ item.auditHour }} 小时</span>
                <span class="check-employee" v-if="item.checkEmployee">
                  <i class="el-icon-user"></i> 审核人: {{ item.checkEmployee }}
                </span>
              </div>
              <div class="work-content">
                <el-input
                  v-model="item.workContent"
                  disabled
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 6 }"
                ></el-input>
              </div>
            </div>

            <div v-if="selectedDateData.length === 0" class="no-data">
              <i class="el-icon-document"></i>
              <span>当日无工时记录</span>
            </div>
          </div>

          <div v-else class="no-date-selected">
            <i class="el-icon-date"></i>
            <span>请选择日期查看工时详情</span>
            <el-tag type="info" style="margin-left: 10px">
              提示：左侧带
              <div
                class="dot"
                style="display: inline-block; margin: 0 4px"
              ></div>
              的日期表示存在工时内容
            </el-tag>
          </div>
        </div>
      </div>
      <!-- <el-pagination
        prev-text="上一页"
        next-text="下一页"
        layout="total, prev, pager, next, slot, jumper"
        :current-page="parameter.pageNow"
        :page-size="parameter.pageSize"
        :total="parameter.total"
        @size-change="handleSizeChange"
        @current-change="pageChangeHandler"
      >
        <span class="el-pagination__jump e_a_pagination">
          <el-input
            size="mini"
            v-model.number="pageSize"
            @blur="handlePageSize"
          ></el-input>
          <span style="padding-top: 1px">条/页</span>
        </span>
      </el-pagination> -->
      <div
        slot="footer"
        class="dialog-footer"
        style="display: flex; justify-content: center"
      >
        <el-button @click="handleCancel">返 回</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    detail: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      parameter: {
        order: "",
        orderRule: "",
        pageNow: 1,
        pageSize: 10000,
        total: 0,
      },
      tableData: [],
      editShow: false,
      pageSize: 10000,
      loading: false,
      currentDate: new Date(),
      selectedDate: null,
      selectedDateData: [],
    };
  },
  watch: {
    isShow: {
      handler(val) {
        this.editShow = val;
        this.parameter = {
          order: "",
          orderRule: "",
          pageNow: 1,
          pageSize: 10000,
          total: 0,
        };
        if (val) {
          this.tableData = [];
          this.getTableData();
        }
      },
    },
  },
  computed: {
    formatSelectedDate() {
      if (!this.selectedDate) return "";
      const date = new Date(this.selectedDate);
      return `${date.getFullYear()}年${
        date.getMonth() + 1
      }月${date.getDate()}日`;
    },
    getTotalHours() {
      return this.selectedDateData.reduce(
        (sum, item) => sum + Number(item.auditHour || 0),
        0
      );
    },
    // 按日期组织数据
    dateGroupedData() {
      const result = {};
      if (this.tableData && this.tableData.length) {
        this.tableData.forEach((item) => {
          const dateStr = item.manhourDate.slice(0, 10);
          if (!result[dateStr]) {
            result[dateStr] = [];
          }
          result[dateStr].push(item);
        });
      }
      return result;
    },
  },
  methods: {
    // 检查日期是否有数据
    hasDataOnDate(date) {
      const dateStr = this.formatDate(date).slice(0, 10);
      return (
        this.dateGroupedData[dateStr] &&
        this.dateGroupedData[dateStr].length > 0
      );
    },

    // 检查是否是选中的日期
    isSelectedDate(date) {
      if (!this.selectedDate) return false;
      const dateStr = this.formatDate(date);
      const selectedStr = this.formatDate(new Date(this.selectedDate));
      return dateStr === selectedStr;
    },

    // 选择日期
    selectDate(date) {
      this.selectedDate = date;
      const dateStr = this.formatDate(date).slice(0, 10);
      this.selectedDateData = this.dateGroupedData[dateStr] || [];
    },
    formatDate(date) {
      let year = date.getFullYear();
      let month = (date.getMonth() + 1).toString().padStart(2, "0");
      let day = date.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    // 根据年月“2025-04”获取每个月开始和结束时间
    getMonthStartEndTime(examineMonth) {
      let year = examineMonth.split("-")[0];
      let month = examineMonth.split("-")[1];
      // 只要年月日
      let start = this.formatDate(new Date(year, month - 1, 1));
      let end = this.formatDate(new Date(year, month, 0));
      return [start, end];
    },
    // getTableData() {
    //   Object.assign(this.parameter, this.detail.queryParams);
    //   this.parameter.userName = this.detail.userName;
    //   this.loading = true;
    //   this.$api.compensationManage
    //     .staffManhourDetail(this.parameter)
    //     .then((res) => {
    //       this.tableData = res.data.records;
    //       this.parameter.total = res.data.total;
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     })
    //     .finally(() => {
    //       this.loading = false;
    //     });
    // },
    // 获取表格数据后处理
    getTableData() {
      Object.assign(this.parameter, this.detail.queryParams);
      this.parameter.userName = this.detail.userName;
      this.loading = true;
      this.$api.compensationManage
        .staffManhourDetail(this.parameter)
        .then((res) => {
          this.tableData = res.data.records;
          this.parameter.total = res.data.total;

          // 将日历切换到考核月份
          // if (this.detail.examineMonth) {
          //   const [year, month] = this.detail.examineMonth.split('-');
          //   this.currentDate = new Date(year, month - 1, 1);
          // }

          // 如果有数据，默认选中第一条数据的日期
          if (this.tableData && this.tableData.length > 0) {
            const firstDate = new Date(this.tableData[0].manhourDate);
            this.currentDate = firstDate;
            this.selectDate(firstDate);
          } else {
            this.$message.warning("未查询到数据")
          }

        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleCancel() {
      this.$emit("closeEdit", true);
    },
    /**
     * 点击 "表格 -- 分页" 操作
     * @param page      当前页
     */
    pageChangeHandler(page) {
      this.parameter.pageNow = page;
      this.getTableData(); /** 加载 "发票申请" 数据 **/
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val;
      this.parameter.pageNow = 1;
      this.getTableData();
    },
    handlePageSize() {
      if (!this.pageSize) {
        this.pageSize = 1;
      }
      this.parameter.pageSize = this.pageSize;
      this.getTableData();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/config.scss";
.hour-base-info {
  padding: 16px 20px 6px 20px;
  background-color: #f5f7fa;
  border-radius: 4px;
  margin-bottom: 10px;
  //   display: flex;
  flex-wrap: wrap;
}

/deep/.el-dialog__header {
  border-bottom: 1px solid #ebeef5;
  .dialog-header {
    .title-content {
      display: flex;
      flex-direction: row;
      align-items: center;

      .main-title {
        font-size: 18px;
        font-weight: bold;
        margin-right: 8px;
        color: #303133;
      }

      .sub-title {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;

        .tag {
          font-size: 13px;
          padding: 2px 8px;
          border-radius: 4px;
          display: inline-flex;
          align-items: center;

          i {
            margin-right: 4px;
          }
        }

        .project-tag {
          background-color: #ecf5ff;
          color: #409eff;
        }
        .business-tag {
          background-color: #f0f9eb;
          color: #67c23a;
        }
        .manage-tag {
          background-color: #fdf6ec;
          color: #e6a23c;
        }
        .user-tag,
        .date-tag {
          background-color: #f5f7fa;
          color: #606266;
        }
      }
    }
  }
}
/deep/.el-dialog__body {
  padding: 20px 20px 10px 20px;
}
/deep/.el-table__body-wrapper {
  max-height: 45vh;
  overflow: auto;
}
.calendar-content-layout {
  display: flex;

  .calendar-container {
    width: 40%;
    border-right: 1px solid #ebeef5;
    padding: 0px;
    padding-right: 15px;
    overflow: auto;
    // border: 1px solid #ebeef5;
    // border-radius: 4px;
    // padding: 10px;

    .calendar-day {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      position: relative;

      &.has-data {
        font-weight: bold;
        color: #409eff;
      }

      &.is-selected {
        background-color: #409eff;
        color: white;
        border-radius: 4px;
      }

      .dot {
        position: absolute;
        bottom: 2px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #67c23a;
      }
    }
  }

  .content-container {
    flex: 1;
    padding-left: 15px;
    overflow: auto;

    .date-info {
      .date-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        .date-title {
          font-size: 16px;
          font-weight: bold;
        }

        .total-hours {
          color: #409eff;
          font-weight: bold;
        }
      }

      .work-item {
        margin-bottom: 15px;
        padding: 10px;
        border-radius: 4px;
        background-color: #f5f7fa;

        .work-header {
          display: flex;
          justify-content: space-between;
          margin-bottom: 8px;

          .work-hours {
            font-weight: bold;
            color: #606266;
          }
          .check-employee {
            color: #909399;
            font-size: 13px;
            display: flex;
            align-items: center;

            i {
              margin-right: 4px;
            }
          }
        }
      }
    }

    .no-date-selected,
    .no-data {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #909399;

      i {
        font-size: 48px;
        margin-bottom: 10px;
      }
    }
    .no-date-selected {
      .el-tag {
        .dot {
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: #67c23a;
          vertical-align: middle;
        }
      }
    }
  }
}
/deep/.el-calendar__body {
  padding: 12px 20px;
}
/deep/ .el-calendar-table .el-calendar-day {
  height: 60px;
  padding: 2px;
}

/deep/ .el-textarea.is-disabled .el-textarea__inner {
  background-color: white;
}
</style>
